import { useMatchesData } from '@ui/utils/remix';
import invariant from 'tiny-invariant';
import type { User } from '~/db/user.server';

function isUser(user: any): user is User {
	return (
		user &&
		typeof user === 'object' &&
		typeof user.email === 'string' &&
		typeof user.name === 'string'
	);
}

export function useOptionalUser(): User | undefined {
	const data = useMatchesData({ id: 'root' });
	if (!data || !isUser(data.user)) {
		return undefined;
	}
	return data.user;
}

export function useUser(): User {
	const maybeUser = useOptionalUser();
	invariant(
		maybeUser,
		'No user found in root loader, but user is required by useUser. If user is optional, try useOptionalUser instead.',
	);

	return maybeUser;
}
